@import 'utilities/references.scss';

.tehranto-footer {
    background-color: $primary;
    @include app-bp-md(min-height, nth($footerHeight, 1), nth($footerHeight, 2));

    .links {
        gap: 24px;

        @include app-pre-bp(md) {
            row-gap: 16px;
            column-gap: 32px;
        }
    }

    .rights {
        direction: ltr;
        font-family: AnjomanMax;
        height: 60px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 51.93%, rgba(0, 0, 0, 0.05) 100%);
    }
}
