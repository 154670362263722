@import 'utilities/references.scss';

.bg-content {
    position: relative;
    overflow: visible;

    .background {
        position: absolute;
        width: 100%;
        z-index: -1;
        display: flex;
        justify-content: center;
        @include app-bp-md(top, 0, -24px);

        > img {
            width: 100%;
            height: auto;

            @include app-pre-bp(md) {
                width: auto;
                height: 100%;
            }
        }
    }

    .card-content {
        flex-direction: row-reverse;
        @include app-pre-bp(md) {
            display: grid;
            grid-template-columns: repeat(2, 169px);
            justify-content: center;
            gap: 15px;
        }
        .card {
            position: relative;
            width: 265px;
            height: 252px;
            border-radius: 20px;
            @include app-pre-bp(md) {
                width: 169px;
                height: 169px;
                gap: 0;
                > p {
                    position: absolute;
                    top: 12px;
                    font-size: 16px;
                }
                .card-svg {
                    > p {
                        font-size: 28px !important;
                    }
                }
            }
            &:nth-child(1) {
                background: rgb(54, 229, 174);
                background: linear-gradient(180deg, rgba(54, 229, 174, 1) 50%, rgba(54, 229, 174, 0.5) 100%);
                @include app-pre-bp(md) {
                    background: rgb(0, 185, 228);
                    background: linear-gradient(225deg, rgba(0, 185, 228, 1) 50%, rgba(54, 229, 174, 0.75) 100%);
                }
            }
            &:nth-child(2) {
                background: rgb(0, 209, 209);
                background: linear-gradient(180deg, rgba(0, 209, 209, 1) 50%, rgba(0, 209, 209, 0.5) 100%);
                @include app-pre-bp(md) {
                    background: rgb(0, 185, 228);
                    background: linear-gradient(135deg, rgba(0, 185, 228, 0.75) 50%, rgba(54, 229, 174, 1) 100%);
                }
            }
            &:nth-child(3) {
                background: rgb(0, 185, 228);
                background: linear-gradient(180deg, rgba(0, 185, 228, 1) 50%, rgba(0, 185, 228, 0.5) 100%);
                @include app-pre-bp(md) {
                    background: rgb(0, 185, 228);
                    background: linear-gradient(315deg, rgba(0, 185, 228, 0.75) 50%, rgba(54, 229, 174, 1) 100%);
                }
            }
            &:nth-child(4) {
                background: rgb(0, 157, 224);
                background: linear-gradient(180deg, rgba(0, 157, 224, 1) 50%, rgba(0, 157, 224, 0.5) 100%);
                @include app-pre-bp(md) {
                    background: rgb(0, 185, 228);
                    background: linear-gradient(45deg, rgba(0, 185, 228, 0.75) 50%, rgba(54, 229, 174, 1) 100%);
                }
            }

            > p {
                text-align: center;
            }
            .card-svg {
                width: 120px;
                height: 120px;
                position: relative;
                > p {
                    position: absolute;
                    top: 58px;
                    font-size: 38px;
                }
            }
        }
    }
}
