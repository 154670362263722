@import 'utilities/references.scss';

.primary-card-lg {
    width: 100%;
    border: 1.5px solid $dark6;
    @include app-bp(md) {
        margin-bottom: 12px;
    }

    @include app-pre-bp(md) {
        border: none;

        &:not(:last-child) {
            border-bottom: 12px solid rgba($dark5, 0.25);
        }
    }

    .slider-lg {
        width: 100%;
        height: 265px;
        background-color: gray;
    }

    .top-section-lg {
        border-bottom: 1.5px solid $dark6;

        .border-bottom {
            border-bottom: 1.5px solid $dark6;
        }

        .brand-cover {
            border-radius: 10px;
            border: 1.5px solid $dark6;
            object-fit: cover;
        }

        > p {
            text-align: justify;
        }

        .brand-info {
            background-color: $fieldBG;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;

            p {
                font-size: 16px;
                color: $dark3;

                &:nth-child(2) {
                    color: $dark4;
                }
            }
        }
    }

    .bottom-section-lg {
        > button {
            &:disabled {
                > p {
                    color: $dark4;
                }
            }
            > p {
                color: #fff;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .terms-lg,
        .comments-lg {
            cursor: pointer;
        }
    }
}
