@import 'utilities/references.scss';

.service-filter-layout {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1.5px solid $dark6;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    padding: 28px;

    .select-box {
        width: 100%;
    }

    .child-collapse {
        padding: 0 !important;
        color: darkblue !important;
    }
    .nowrap {
        text-wrap: nowrap !important;
    }
    .slider {
        pointer-events: none !important;
        div[class^='ant-slider-handle'] {
            pointer-events: auto !important;
        }
    }
    .parent-node {
        div[class^='ant-tree-treenode'] {
            @include ltr {
                direction: rtl;
            }
            @include rtl {
                direction: ltr;
            }
            span[class^='ant-tree-node-content-wrapper'] {
                @include ltr {
                    direction: ltr;
                }
                @include rtl {
                    direction: rtl;
                }
                @include rtl {
                    padding-right: 24px !important;
                }
                @include ltr {
                    padding-left: 24px !important;
                }
            }
        }
    }
    .checkbox-list {
        max-height: 260px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 26px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        }
        > div {
            max-height: 160px;
            overflow-y: auto;
            position: relative;
            &::-webkit-scrollbar-track {
                background-color: $fieldBG;
            }
            &::-webkit-scrollbar-thumb {
                background-color: $primary;
            }
            &::-webkit-scrollbar {
                width: 3px;
            }
            > div {
                display: flex;
                flex-direction: column;

                > label {
                    margin-bottom: 8px !important;
                }
            }
        }
    }

    @include app-pre-bp(lg) {
        width: 100%;
        border-radius: 0;
    }
}
