@import 'utilities/references.scss';

.bottom-navigation-layout {
    width: 100%;
    background-color: #ffffff;
    padding: 7px 23px;
    z-index: 20;
    transition: bottom 0.5s ease;

    .container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        max-width: 400px;
        margin: 0 auto;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;
    }
}
