@import 'utilities/references.scss';

.card-layout {
    border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
    border-radius: 20px;
    margin-bottom: 18px;
    padding: 16px;
    background-color: #ffffff;
    min-width: 240px;
    @include app-pre-bp(lg) {
        padding: 0;
        border-radius: 0;
        border: none;
        padding-bottom: 32px;
    }

    .image {
        position: relative;
        width: 100%;
        height: 200px;
        border-radius: 10px;
        @include app-pre-bp(lg) {
            height: 256px;
            border-radius: 0;
        }
        margin-bottom: 12px;

        > img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
            @include app-pre-bp(lg) {
                border-radius: 0;
            }
        }

        > span {
            color: #ffffff;
            padding: 5px 8px;
            border-radius: 8px;
            background-color: $primary;
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }

    > div:last-child {
        @include app-pre-bp(lg) {
            padding: 0 32px;
        }
    }

    > div > p {
        margin-bottom: 12px;
    }
}
