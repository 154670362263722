@import 'utilities/references.scss';

.service-modal {
    max-width: 764px;
    margin: auto;
    @include app-pre-bp(md) {
        flex-direction: column;
    }
    .info {
        flex: 1;
        @include app-bp(md) {
            max-width: 460px;
        }

        .phone {
            @include rtl {
                direction: ltr !important;
                text-align: right;
            }
        }
    }

    .qr-code {
        flex: 1;
        height: 180px;
        overflow: hidden;
        @include app-pre-bp(md) {
            display: flex;
            justify-content: center;
        }
        @include app-bp(md) {
            max-width: 278px;
            height: 278px;
        }

        img {
            width: 100%;

            @include app-bp(md) {
                transform: scale(1.25);
            }
        }
    }
}

.service-items {
    border-radius: 10px;
    background: linear-gradient(to top, rgba(0, 157, 224, 0.1), rgba(0, 157, 224, 0.1));
    position: relative;
    max-width: 764px;
    margin: 24px auto;

    > div > ul {
        > li {
            margin-bottom: 8px;
        }
    }
    img {
        position: absolute;
        bottom: 12px;
        @include rtl {
            left: 24px;
        }
        @include ltr {
            right: 24px;
        }
    }
}
