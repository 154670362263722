@import 'utilities/references.scss';

.container {
    overflow-x: clip;
    @include app-bp-md(
        min-height,
        calc(100vh - nth($headerHeight, 1) - nth($footerHeight, 1)),
        calc(100vh - nth($headerHeight, 2) - nth($footerHeight, 2) - $bottomNavigation)
    );

    &.no-layout {
        @include app-bp-md(min-height, 100vh, calc(100vh - $bottomNavigation));
    }

    &.no-header {
        @include app-bp-md(
            min-height,
            calc(100vh - nth($footerHeight, 1)),
            calc(100vh - nth($footerHeight, 2) - $bottomNavigation)
        );
    }

    &.no-footer {
        @include app-bp-md(
            min-height,
            calc(100vh - nth($headerHeight, 1)),
            calc(100vh - nth($headerHeight, 2) - $bottomNavigation)
        );
    }

    > * {
        width: 100%;
    }
}
