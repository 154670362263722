@import 'utilities/references.scss';

.primary-card {
    width: 100%;
    border: 1.5px solid $dark6;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 12px;

    .top-section {
        border-bottom: 1.5px solid $dark6;

        .details {
            width: 100%;

            .service-info {
                margin-bottom: 4px;
            }

            .desc-text {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-align: justify;
            }

            .terms,
            .comments {
                cursor: pointer;
            }

            .service-title {
                margin-bottom: 12px;
                padding-bottom: 12px;
                border-bottom: 1.5px solid $dark6;
            }

            .brand-title {
                margin-bottom: 12px;

                img {
                    border-radius: 10px;
                    border: 1px solid $dark6;
                    object-fit: contain;
                }
            }

            .brand-info {
                flex: 1;
                background-color: $fieldBG;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 8px;
                gap: 4px;

                p {
                    font-size: 14px;
                    color: $dark4;
                    line-height: 20px;

                    &:first-child {
                        font-size: 16px;
                        color: $dark3;
                    }
                }
            }
        }
    }

    .bottom-section {
        gap: 16px;

        .btn {
            justify-content: flex-end;

            > button {
                max-width: 360px;
                height: 50px;

                &:first-child {
                    aspect-ratio: 1;
                }

                > p {
                    color: #fff;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &:disabled {
                    > p {
                        color: $dark4;
                    }
                }
            }
        }

        .info {
            overflow: hidden;
            > div {
                width: calc(100% - 66px);
                > a {
                    width: 100%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }
            }
            > a > img {
                border-radius: 10px;
                border: 1.5px solid $dark6;
                object-fit: cover;
            }
        }
    }
}
