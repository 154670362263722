@import 'utilities/references.scss';

.hiring-filter {
    border-radius: 20px;
    border: 1.5px solid $dark6;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    max-width: 360px;
    padding: 24px 28px;
    height: fit-content;
    position: relative;

    .form-item {
        padding-top: 16px;
        border-top: 1.5px solid rgba($dark5, 0.25);

        > div > div > label {
            padding-bottom: 8px;
        }
    }

    .range-label {
        position: relative;
        top: -16px;
    }
}

.submit-btn {
    position: sticky !important;
    bottom: 16px;
}
