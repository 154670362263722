@import 'utilities/references.scss';
.navigation-wrapper {
    width: 220px;
    height: 200px;
    border-radius: 10px;
    position: relative;

    @include app-pre-bp(lg) {
        width: 100%;
        height: 256px;
    }

    .loading {
        width: 100%;
        height: 200px;
        @include app-pre-bp(lg) {
            height: 256px;
        }
    }

    .slider {
        width: 220px;
        height: 200px;
        border-radius: 10px;

        @include app-pre-bp(lg) {
            width: 100%;
            height: 256px;
            border-radius: 0;
        }

        .slide-item > img {
            object-fit: cover;
            @include app-pre-bp(lg) {
            }
        }

        .slide-item > video {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .empty-state {
            background-color: rgba($dark3, 0.1);
        }
    }

    .dots {
        position: absolute;
        gap: 5px;
        bottom: 8px;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        @include app-pre-bp(lg) {
            bottom: 12px;
        }

        .dot {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 100%;

            &.active {
                background-color: #fff;
            }

            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 32px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));

        @include app-bp(md) {
            border-radius: 0 0 10px 10px;
        }
    }
}
