@import 'utilities/references.scss';

.tehranto-header {
    z-index: 100;
    position: sticky;
    top: 0;
    backdrop-filter: blur(16px);
    transition: 0.3s ease-in-out;
    box-shadow: 0px 4px 32px 0px #00000000;
    @include app-bp-md(height, nth($headerHeight, 1), nth($headerHeight, 2));

    &.bg-white {
        background-color: white;
    }

    &.collapsed {
        box-shadow: 0px 4px 32px 0px #0000000d;

        @include app-bp(md) {
            height: $collapseHeaderHeight;
        }
    }

    .links {
        gap: 20px;

        @include app-pre-bp(lg) {
            gap: 12px;
        }
    }

    .tools {
        gap: 24px;

        @include app-pre-bp(lg) {
            gap: 12px;
        }
    }
}
