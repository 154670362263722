@import 'utilities/references.scss';

.slider-limited-width {
    max-width: 1120px;
}

.slider-loading {
    font-size: 24px;
    height: 256px;
}

.slider-no-data {
    height: 128px;
}

// .no-overflow {
//     // overflow: unset !important;
// }

.slider-wrapper {
    position: relative;

    > svg {
        position: absolute;
        background-color: $primary;
        color: white;
        border-radius: 8px;
        cursor: pointer;
        z-index: 10;
        transition: opacity 0.2s;
        @include app-bp-md(width, 32px, 28px);
        @include app-bp-md(height, 32px, 28px);
        @include app-bp-md(top, calc(50% - 16px), calc(50% - 14px));
        @include app-bp-md(padding, 6px, 4px);

        &.left-arrow {
            @include app-bp-md(left, 48px, 24px);
        }

        &.right-arrow {
            @include app-bp-md(right, 48px, 24px);
        }

        &.disable {
            opacity: 0.4;
        }
    }

    &.show-hide-arrows {
        > svg {
            opacity: 0;
            @include app-bp-md(opacity, 0, 1);
        }

        &:hover {
            > svg {
                opacity: 1;

                &.disable {
                    opacity: 0.4;
                }
            }
        }
    }

    .shadow {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 1;
        pointer-events: none;
        @include app-bp-md(width, 100px, 60px);

        @include ltr {
            right: 0;
            background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        }
        @include rtl {
            left: 0;
            background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .second-shadow {
        position: absolute;
        height: 100%;
        top: 0;
        z-index: 1;
        pointer-events: none;
        @include app-bp-md(width, 100px, 60px);

        @include ltr {
            left: 0;
            background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        }
        @include rtl {
            right: 0;
            background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
}
