@import 'utilities/references.scss';

.tools {
    @include app-pre-bp(lg) {
        flex-direction: column;
    }

    .brand {
        @include app-pre-bp(lg) {
            width: 100%;
        }

        > img {
            border-radius: 10px;
            border: 1px solid rgba($dark5, 0.25);
            object-fit: cover;
        }
    }

    .btns {
        @include app-pre-bp(lg) {
            margin-top: 24px;
            flex-direction: column-reverse;
            width: 100%;

            > button,
            > a > button {
                width: 100%;
            }
        }
    }
}
