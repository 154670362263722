@import 'utilities/references.scss';

.job-card {
    border: 1px solid rgba($dark5, 0.25);
    border-radius: 20px;

    .header {
        padding: 0 20px;
        min-height: 64px;
        border-bottom: 1px solid rgba($dark5, 0.25);
    }

    .content {
        padding: 20px;

        .tabs {
            @include app-pre-bp(md) {
                justify-content: center;
            }

            .item {
                min-height: 38px;
                padding: 0 12px;
                transition: 0.3s;
                border-radius: 10px;
                background-color: rgba($dark5, 0);

                &.active {
                    background-color: rgba($dark5, 0.15);
                }
            }
        }

        .features {
            border-radius: 10px;
            background-color: rgba($dark5, 0.15);
            gap: 10px;
            padding: 12px;

            .row {
                @include app-bp(md) {
                    align-items: center;
                }

                .item {
                    gap: 6px;
                }
            }
        }
    }
}
