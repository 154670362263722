@import 'utilities/references.scss';

.header-drawer {
    > div:first-child > div {
        flex-direction: row-reverse;
    }

    .collapse > div {
        box-shadow: none !important;
        padding: 16px 0;
        margin: 0 !important;
    }
}
