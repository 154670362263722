@import 'utilities/references.scss';

.text-layout {
    padding: 24px 32px;
    background-color: $fieldBG;
    border-radius: 20px;
    > div > p {
        font-size: 16px;
        font-weight: 400;
        color: $dark2;
        text-align: justify;
    }

    @include app-pre-bp(md) {
        padding: 16px 24px;
    }
}

.comments {
    > div {
        margin: 12px 0;
    }
    .loading {
        min-height: 800px;
    }
    .pagination-review-layout {
        margin: 40px 0;
        .pagination-review {
            width: 100%;
            border-radius: 20px;
            border: 1.5px solid $dark6;
            padding: 3px 0;
        }
    }
}
