@import 'utilities/references.scss';

.bottom-navigation {
    height: $bottomNavigation;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: white;
    z-index: 100;
    box-shadow: 0px -4px 16px 0px #0000000a;
    transition: bottom 0.5s ease;

    .container {
        position: relative;
        display: flex;
        gap: 8px;
        margin: auto;
        max-width: 410px;

        .fixed {
            position: absolute;
            bottom: -96px;
            left: 0;
            transition: bottom 0.5s ease;
        }

        .show {
            z-index: 2;
            bottom: 0;
        }

        .services-btn {
            min-width: 52px;
            max-width: 52px;
            height: 52px;
            border-radius: 100%;
            background-color: $primary;
            box-shadow: 0 4px 20px 0 rgba($color: $primary, $alpha: 0.15);
            > svg {
                color: #ffffff;
            }
        }
    }
}
