@import 'utilities/references.scss';

.card-layout {
    padding: 24px 24px;
    border-radius: 20px;
    border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
    background-color: white;

    > p:nth-child(2) {
        border-bottom: 1px solid rgba($color: $dark5, $alpha: 0.25);
        margin-bottom: 12px;
        margin-top: 4px;
    }
    > p:nth-child(4) {
        border-bottom: 1px solid rgba($color: $dark5, $alpha: 0.25);
        padding-bottom: 12px;
        margin-bottom: 12px;
    }
}
