@import 'utilities/references.scss';
.houses-card-layout {
    border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
    border-radius: 20px;
    background-color: white;

    .image {
        height: 200px;
        border-radius: 10px;
        position: relative;
        > img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        > span {
            position: absolute;
            color: $orange;
            top: 10px;
            @include ltr {
                right: 10px;
            }
            @include rtl {
                left: 10px;
            }
            padding: 2px 10px;
            border-radius: 8px;
            background-color: #ffffff;
            max-width: calc(100% - 20px);
        }

        .empty-state {
            background-color: rgba($dark3, 0.1);
            border-radius: 10px;
        }
    }

    > p:nth-child(3) {
        margin-bottom: 12px;
    }
    > p:nth-child(4) {
        padding-bottom: 8px;
        border-bottom: 1px solid rgba($color: $dark5, $alpha: 0.25);
        margin-bottom: 12px;
    }
}
