@import 'utilities/references.scss';
.navigation-wrapper {
    @keyframes timeline {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }

    position: relative;

    .loading {
        width: 100%;
        height: 100%;
    }

    .slider {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        min-height: 350px;

        > div {
            max-height: 100% !important;
            > img,
            > video {
                object-fit: cover;
                background: rgba($dark2, 0.2);
                border-radius: 20px !important;
                height: 100%;
            }
        }

        .empty-state {
            min-height: inherit;
            background-color: rgba($dark3, 0.1);
            border-radius: 10px;
        }
    }

    .btn-slider {
        padding: 0 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        > button {
            width: 26px;
            height: 26px;
            border-radius: 7px;
            position: relative;

            > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        @include app-pre-bp(md) {
            padding: 0 14px;
        }
    }

    .shadow {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        pointer-events: none;
        border-radius: 0 0 20px 20px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
        z-index: 20;
    }

    .dots {
        position: absolute;
        bottom: 20px;
        padding: 0 37px;
        display: flex;
        gap: 12px;
        .dot {
            cursor: pointer;
            position: relative;
            flex: 1;
            height: 3px;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.25);

            .active {
                position: absolute;
                left: 0;
                display: block;
                animation: timeline 3s forwards linear;
                height: 3px;
                border-radius: 10px;
                background-color: #ffffff;

                &.rtl-timeline {
                    right: 0;
                }
            }
        }

        @include app-pre-bp(md) {
            bottom: 12px;
        }
    }

    @include app-pre-bp(md) {
        width: 100%;
    }
}
