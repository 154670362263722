@import 'utilities/references.scss';

.form-layout {
    .ltr-input > div > div:last-child {
        * {
            width: fit-content;
            direction: ltr !important;
        }
    }

    .input-phone * {
        direction: ltr;
        text-align: left;

        span[class~='ant-select-selection-item'] {
            padding-inline-end: 12px !important;
        }

        input {
            padding-inline: 12px;
        }
        font-family: 'Twemoji Country Flags', 'Helvetica', 'Comic Sans', serif !important;
    }

    .actions {
        margin-top: 3rem;
        display: flex;
        gap: 16px;
        @include app-bp(md) {
            align-items: center;
            > * {
                flex: 1;
            }
        }
        @include app-pre-bp(md) {
            flex-direction: column-reverse;
            > * {
                width: 100%;
            }
        }
    }
}
