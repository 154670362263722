@import 'utilities/references.scss';

.review-card-layout {
    width: 100%;
    background-color: #ffffff;
    border: 1.5px solid $dark6;
    border-radius: 20px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

    .profile {
        border-bottom: 1.5px solid $dark6;
        margin-bottom: 20px;
        > div {
            .star-date {
                > span {
                    gap: 5px;
                }

                @include app-pre-bp(md) {
                    justify-content: start;
                    gap: 12px;
                }
            }
        }

        .not-image {
            width: 52px;
            height: 52px;
            padding: 8px 12px;
            border-radius: 100%;
            border: 1.5px solid $dark3;
            background-color: rgba($color: $dark4, $alpha: 0.1);
        }

        @include app-pre-bp(md) {
            padding-left: 28px;
            padding-right: 28px;
        }
    }

    .comment {
        @include app-pre-bp(md) {
            padding-left: 28px;
            padding-right: 28px;
        }
    }

    > div {
        > a {
            > svg {
                transform: rotate(90deg);
            }
        }
        > p {
            text-align: justify;
        }
    }
}
